<template>
  <footer class="footer">
    <div class="content">
      <!-- eslint-disable -->
      <p class="footer-text" v-html="footerText">
        <!-- eslint-enable -->
      </p>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TheFooter',
  computed: {
    footerText() {
      return this.$omwConfig.display.footerText.replace(
        '{year}',
        this.currentYear,
      );
    },
  },
});
</script>

<style scoped lang="scss">
.footer-text {
  font-weight: 500;
}
</style>
